section {
  padding: 4rem 0;
}

.section-intro .intro-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
.section-intro .intro-inner .text-wrapper {
  flex: 0 0 100%;
  min-width: 100%;
  width: 100%;
  max-width: 100%;
}
.section-intro .intro-inner .images-wrapper {
  flex: 0 0 100%;
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.section-intro .intro-inner .images-wrapper .image-wrapper {
  flex: 0 0 100%;
  min-width: 100%;
  width: 100%;
  max-width: 100%;
}
.section-intro .intro-inner .images-wrapper .image-wrapper:not(:first-child) {
  padding-top: 1rem;
}
.section-intro .intro-inner .images-wrapper .image-wrapper:not(:last-child) {
  padding-bottom: 1rem;
}
.section-intro .intro-inner .images-wrapper .image-wrapper img {
  display: block;
  width: 100%;
}
@media (min-width: 600px) {
  .section-intro .intro-inner .images-wrapper .image-wrapper {
    flex: 0 0 50%;
    min-width: 50%;
    width: 50%;
    max-width: 50%;
    padding: 0 1rem;
  }
  .section-intro .intro-inner .images-wrapper .image-wrapper img {
    display: block;
    width: 100%;
  }
}
@media (min-width: 900px) {
  .section-intro .intro-inner .text-wrapper {
    flex: 0 0 60%;
    min-width: 60%;
    width: 60%;
    max-width: 60%;
    padding-right: 1rem;
  }
  .section-intro .intro-inner .images-wrapper {
    flex: 0 0 40%;
    min-width: 40%;
    width: 40%;
    max-width: 40%;
    padding-left: 1rem;
    display: flex;
    align-items: flex-start;
  }
  .section-intro .intro-inner .images-wrapper .image-wrapper {
    flex: 0 0 100%;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
  .section-intro .intro-inner .images-wrapper .image-wrapper:not(:first-child) {
    padding-top: 1rem;
  }
  .section-intro .intro-inner .images-wrapper .image-wrapper:not(:last-child) {
    padding-bottom: 1rem;
  }
  .section-intro .intro-inner .images-wrapper .image-wrapper img {
    display: block;
    width: 100%;
  }
}

.section-places {
  background-color: #d6cbe8;
}
.section-places .places-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
.section-places .places-inner .text-wrapper {
  order: 1;
  flex: 0 0 100%;
  min-width: 100%;
  width: 100%;
  max-width: 100%;
}
.section-places .places-inner .text-wrapper h2 {
  color: #3c2878;
}
.section-places .places-inner .text-wrapper .map-key-wrapper {
  text-align: left;
}
.section-places .places-inner .text-wrapper .map-key-wrapper li {
  display: block;
}
.section-places .places-inner .text-wrapper .map-key-wrapper li .map-key-image-wrapper {
  transform: translateY(0.25rem);
}
.section-places .places-inner .images-wrapper {
  order: 2;
  flex: 0 0 100%;
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.section-places .places-inner .images-wrapper .image-wrapper {
  flex: 0 0 75%;
  min-width: 75%;
  width: 75%;
  max-width: 75%;
}
.section-places .places-inner .images-wrapper .image-wrapper:not(:first-child) {
  padding-top: 1rem;
}
.section-places .places-inner .images-wrapper .image-wrapper:not(:last-child) {
  padding-bottom: 1rem;
}
.section-places .places-inner .images-wrapper .image-wrapper img {
  display: block;
  width: 100%;
  border: 4px solid #8073a7;
}
@media (min-width: 600px) {
  .section-places .places-inner .text-wrapper {
    order: 2;
    flex: 0 0 60%;
    min-width: 60%;
    width: 60%;
    max-width: 60%;
    padding-left: 1rem;
  }
  .section-places .places-inner .images-wrapper {
    order: 1;
    flex: 0 0 40%;
    min-width: 40%;
    width: 40%;
    max-width: 40%;
    padding-right: 1rem;
    display: flex;
    align-items: flex-start;
  }
  .section-places .places-inner .images-wrapper .image-wrapper {
    flex: 0 0 100%;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
  .section-places .places-inner .images-wrapper .image-wrapper:not(:first-child) {
    padding-top: 1rem;
  }
  .section-places .places-inner .images-wrapper .image-wrapper:not(:last-child) {
    padding-bottom: 1rem;
  }
  .section-places .places-inner .images-wrapper .image-wrapper img {
    display: block;
    width: 100%;
  }
}

.section-exercises .exercises-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
.section-exercises .exercises-inner .text-wrapper {
  flex: 0 0 100%;
  min-width: 100%;
  width: 100%;
  max-width: 100%;
}
.section-exercises .exercises-inner .images-wrapper {
  flex: 0 0 100%;
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.section-exercises .exercises-inner .images-wrapper .image-wrapper {
  flex: 0 0 100%;
  min-width: 100%;
  width: 100%;
  max-width: 100%;
}
.section-exercises .exercises-inner .images-wrapper .image-wrapper:not(:first-child) {
  padding-top: 1rem;
}
.section-exercises .exercises-inner .images-wrapper .image-wrapper:not(:last-child) {
  padding-bottom: 1rem;
}
.section-exercises .exercises-inner .images-wrapper .image-wrapper img {
  display: block;
  width: 100%;
}
@media (min-width: 600px) {
  .section-exercises .exercises-inner .images-wrapper .image-wrapper {
    flex: 0 0 50%;
    min-width: 50%;
    width: 50%;
    max-width: 50%;
    padding: 0 1rem;
  }
  .section-exercises .exercises-inner .images-wrapper .image-wrapper img {
    display: block;
    width: 100%;
  }
}
@media (min-width: 900px) {
  .section-exercises .exercises-inner .text-wrapper {
    flex: 0 0 60%;
    min-width: 60%;
    width: 60%;
    max-width: 60%;
    padding-right: 1rem;
  }
  .section-exercises .exercises-inner .images-wrapper {
    flex: 0 0 40%;
    min-width: 40%;
    width: 40%;
    max-width: 40%;
    padding-left: 1rem;
    display: flex;
    align-items: center;
  }
  .section-exercises .exercises-inner .images-wrapper .image-wrapper {
    flex: 0 0 100%;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
  .section-exercises .exercises-inner .images-wrapper .image-wrapper:not(:first-child) {
    padding-top: 1rem;
  }
  .section-exercises .exercises-inner .images-wrapper .image-wrapper:not(:last-child) {
    padding-bottom: 1rem;
  }
  .section-exercises .exercises-inner .images-wrapper .image-wrapper img {
    display: block;
    width: 100%;
  }
}
.section-exercises .exercises-image-wrapper {
  padding-top: 1rem;
}
.section-exercises .exercises-image-wrapper svg {
  height: 50px;
  width: auto;
}
.section-exercises .downloads-text {
  padding-top: 1rem;
}
.section-exercises .downloads-wrapper {
  padding-top: 1rem;
  display: flex;
}
.section-exercises .downloads-wrapper .download-buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: stretch;
}
.section-exercises .downloads-wrapper .download-buttons-wrapper .download-button-wrapper {
  flex: 0 0 250px;
  min-width: 250px;
  width: 250px;
  max-width: 250px;
  padding: 0 1rem 1rem 0;
}
.section-exercises .downloads-wrapper .download-buttons-wrapper .download-button-wrapper .download-button {
  display: block;
  color: #fff;
  padding: 0.5rem 0.5rem;
  text-align: center;
  border-radius: 0.25rem;
}
.section-exercises .downloads-wrapper .download-buttons-wrapper .download-button-wrapper .download-button:hover, .section-exercises .downloads-wrapper .download-buttons-wrapper .download-button-wrapper .download-button:focus {
  box-shadow: 0 0 2px 2px rgba(60, 40, 120, 0.5);
}

.section-exhibition {
  background-color: #fddac4;
}
.section-exhibition .exhibition-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
.section-exhibition .exhibition-inner .text-wrapper {
  order: 1;
  flex: 0 0 100%;
  min-width: 100%;
  width: 100%;
  max-width: 100%;
}
.section-exhibition .exhibition-inner .text-wrapper h2 {
  color: #f68b4f;
}
.section-exhibition .exhibition-inner .text-wrapper .map-key-wrapper {
  text-align: left;
}
.section-exhibition .exhibition-inner .text-wrapper .map-key-wrapper li {
  display: block;
}
.section-exhibition .exhibition-inner .text-wrapper .map-key-wrapper li .map-key-image-wrapper {
  transform: translateY(0.25rem);
}
.section-exhibition .exhibition-inner .images-wrapper {
  order: 2;
  flex: 0 0 100%;
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.section-exhibition .exhibition-inner .images-wrapper .image-wrapper {
  flex: 0 0 75%;
  min-width: 75%;
  width: 75%;
  max-width: 75%;
  background-color: white;
}
.section-exhibition .exhibition-inner .images-wrapper .image-wrapper:not(:first-child) {
  padding-top: 1rem;
}
.section-exhibition .exhibition-inner .images-wrapper .image-wrapper:not(:last-child) {
  padding-bottom: 1rem;
}
.section-exhibition .exhibition-inner .images-wrapper .image-wrapper img {
  display: block;
  width: 100%;
  border: 4px solid #f68b4f;
}
@media (min-width: 600px) {
  .section-exhibition .exhibition-inner .text-wrapper {
    order: 2;
    flex: 0 0 60%;
    min-width: 60%;
    width: 60%;
    max-width: 60%;
    padding-left: 1rem;
  }
  .section-exhibition .exhibition-inner .images-wrapper {
    order: 1;
    flex: 0 0 40%;
    min-width: 40%;
    width: 40%;
    max-width: 40%;
    padding-right: 1rem;
    display: flex;
    align-items: flex-start;
  }
  .section-exhibition .exhibition-inner .images-wrapper .image-wrapper {
    flex: 0 0 100%;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
  .section-exhibition .exhibition-inner .images-wrapper .image-wrapper:not(:first-child) {
    padding-top: 1rem;
  }
  .section-exhibition .exhibition-inner .images-wrapper .image-wrapper:not(:last-child) {
    padding-bottom: 1rem;
  }
  .section-exhibition .exhibition-inner .images-wrapper .image-wrapper img {
    display: block;
    width: 100%;
  }
}