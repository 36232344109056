@use "../variables" as vars;

section {
    padding: 4rem 0;
}

.section-intro {
    & .intro-inner {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        & .text-wrapper {
            flex: 0 0 100%;
            min-width: 100%;
            width: 100%;
            max-width: 100%;
        }
        & .images-wrapper {
            flex: 0 0 100%;
            min-width: 100%;
            width: 100%;
            max-width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            & .image-wrapper {
                flex: 0 0 100%;
                min-width: 100%;
                width: 100%;
                max-width: 100%;
                &:not(:first-child) {
                    padding-top: 1rem;
                }
                &:not(:last-child) {
                    padding-bottom: 1rem;
                }
                & img {
                    display: block;
                    width: 100%;
                }
            }
        }
        @media (min-width: map-get(vars.$breakpoints,"md")) {
            & .images-wrapper {
                & .image-wrapper {
                    flex: 0 0 50%;
                    min-width: 50%;
                    width: 50%;
                    max-width: 50%;
                    padding: 0 1rem;
                    & img {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
        @media (min-width: map-get(vars.$breakpoints,"lg")) {
            & .text-wrapper {
                flex: 0 0 60%;
                min-width: 60%;
                width: 60%;
                max-width: 60%;
                padding-right: 1rem;
            }
            & .images-wrapper {
                flex: 0 0 40%;
                min-width: 40%;
                width: 40%;
                max-width: 40%;
                padding-left: 1rem;
                display: flex;
                align-items: flex-start;
                & .image-wrapper {
                    flex: 0 0 100%;
                    min-width: 100%;
                    width: 100%;
                    max-width: 100%;
                    &:not(:first-child) {
                        padding-top: 1rem;
                    }
                    &:not(:last-child) {
                        padding-bottom: 1rem;
                    }
                    & img {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.section-places {
    background-color: map-get(vars.$colors, "light-purple");
    & .places-inner {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        & .text-wrapper {
            order: 1;
            flex: 0 0 100%;
            min-width: 100%;
            width: 100%;
            max-width: 100%;
            & h2 {
                color: map-get(vars.$colors, "purple");
            }
            & .map-key-wrapper {
                text-align: left;
                & li {
                    display: block;
                    & .map-key-image-wrapper {
                        transform: translateY(0.25rem);
                    }
                }
            }
        }
        & .images-wrapper {
            order: 2;
            flex: 0 0 100%;
            min-width: 100%;
            width: 100%;
            max-width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            & .image-wrapper {
                flex: 0 0 75%;
                min-width: 75%;
                width: 75%;
                max-width: 75%;
                &:not(:first-child) {
                    padding-top: 1rem;
                }
                &:not(:last-child) {
                    padding-bottom: 1rem;
                }
                & img {
                    display: block;
                    width: 100%;
                    border: 4px solid
                        mix(
                            map-get(vars.$colors, "purple"),
                            map-get(vars.$colors, "white"),
                            65%
                        );
                }
            }
        }
        @media (min-width: map-get(vars.$breakpoints,"md")) {
            & .text-wrapper {
                order: 2;
                flex: 0 0 60%;
                min-width: 60%;
                width: 60%;
                max-width: 60%;
                padding-left: 1rem;
            }
            & .images-wrapper {
                order: 1;
                flex: 0 0 40%;
                min-width: 40%;
                width: 40%;
                max-width: 40%;
                padding-right: 1rem;
                display: flex;
                align-items: flex-start;
                & .image-wrapper {
                    flex: 0 0 100%;
                    min-width: 100%;
                    width: 100%;
                    max-width: 100%;
                    &:not(:first-child) {
                        padding-top: 1rem;
                    }
                    &:not(:last-child) {
                        padding-bottom: 1rem;
                    }
                    & img {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.section-exercises {
    & .exercises-inner {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        & .text-wrapper {
            flex: 0 0 100%;
            min-width: 100%;
            width: 100%;
            max-width: 100%;
        }
        & .images-wrapper {
            flex: 0 0 100%;
            min-width: 100%;
            width: 100%;
            max-width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            & .image-wrapper {
                flex: 0 0 100%;
                min-width: 100%;
                width: 100%;
                max-width: 100%;
                &:not(:first-child) {
                    padding-top: 1rem;
                }
                &:not(:last-child) {
                    padding-bottom: 1rem;
                }
                & img {
                    display: block;
                    width: 100%;
                }
            }
        }
        @media (min-width: map-get(vars.$breakpoints,"md")) {
            & .images-wrapper {
                & .image-wrapper {
                    flex: 0 0 50%;
                    min-width: 50%;
                    width: 50%;
                    max-width: 50%;
                    padding: 0 1rem;
                    & img {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
        @media (min-width: map-get(vars.$breakpoints,"lg")) {
            & .text-wrapper {
                flex: 0 0 60%;
                min-width: 60%;
                width: 60%;
                max-width: 60%;
                padding-right: 1rem;
            }
            & .images-wrapper {
                flex: 0 0 40%;
                min-width: 40%;
                width: 40%;
                max-width: 40%;
                padding-left: 1rem;
                display: flex;
                align-items: center;
                & .image-wrapper {
                    flex: 0 0 100%;
                    min-width: 100%;
                    width: 100%;
                    max-width: 100%;
                    &:not(:first-child) {
                        padding-top: 1rem;
                    }
                    &:not(:last-child) {
                        padding-bottom: 1rem;
                    }
                    & img {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }
    & .exercises-image-wrapper {
        padding-top: 1rem;
        & svg {
            height: 50px;
            width: auto;
        }
    }
    & .downloads-text {
        padding-top: 1rem;
    }
    & .downloads-wrapper {
        padding-top: 1rem;
        display: flex;
        & .download-buttons-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: left;
            align-items: stretch;
            & .download-button-wrapper {
                flex: 0 0 250px;
                min-width: 250px;
                width: 250px;
                max-width: 250px;
                // flex: 0 0 100%;
                // min-width: 100%;
                // width: 100%;
                // max-width: 100%;
                // @media (min-width: map-get(vars.$breakpoints,"md")) {
                //     flex: 0 0 50%;
                //     min-width: 50%;
                //     width: 50%;
                //     max-width: 50%;
                // }
                // @media (min-width: map-get(vars.$breakpoints,"lg")) {
                //     flex: 0 0 33.3%;
                //     min-width: 33.3%;
                //     width: 33.3%;
                //     max-width: 33.3%;
                // }
                padding: 0 1rem 1rem 0;
                & .download-button {
                    display: block;
                    color: map-get(vars.$colors, "white");
                    padding: 0.5rem 0.5rem;
                    text-align: center;
                    border-radius: 0.25rem;
                    &:hover,
                    &:focus {
                        box-shadow: 0 0 2px 2px
                            rgba(map-get(vars.$colors, "purple"), 0.5);
                    }
                }
            }
        }
    }
}

.section-exhibition {
    background-color: map-get(vars.$colors, "light-orange");
    & .exhibition-inner {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        & .text-wrapper {
            order: 1;
            flex: 0 0 100%;
            min-width: 100%;
            width: 100%;
            max-width: 100%;
            & h2 {
                color: map-get(vars.$colors, "orange");
            }
            & .map-key-wrapper {
                text-align: left;
                & li {
                    display: block;
                    & .map-key-image-wrapper {
                        transform: translateY(0.25rem);
                    }
                }
            }
        }
        & .images-wrapper {
            order: 2;
            flex: 0 0 100%;
            min-width: 100%;
            width: 100%;
            max-width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            & .image-wrapper {
                flex: 0 0 75%;
                min-width: 75%;
                width: 75%;
                max-width: 75%;
                &:not(:first-child) {
                    padding-top: 1rem;
                }
                &:not(:last-child) {
                    padding-bottom: 1rem;
                }
                background-color: white;
                & img {
                    display: block;
                    width: 100%;
                    border: 4px solid map-get(vars.$colors, "orange");
                }
            }
        }
        @media (min-width: map-get(vars.$breakpoints,"md")) {
            & .text-wrapper {
                order: 2;
                flex: 0 0 60%;
                min-width: 60%;
                width: 60%;
                max-width: 60%;
                padding-left: 1rem;
            }
            & .images-wrapper {
                order: 1;
                flex: 0 0 40%;
                min-width: 40%;
                width: 40%;
                max-width: 40%;
                padding-right: 1rem;
                display: flex;
                align-items: flex-start;
                & .image-wrapper {
                    flex: 0 0 100%;
                    min-width: 100%;
                    width: 100%;
                    max-width: 100%;
                    &:not(:first-child) {
                        padding-top: 1rem;
                    }
                    &:not(:last-child) {
                        padding-bottom: 1rem;
                    }
                    & img {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }
}
